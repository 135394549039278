import { mapActions } from 'vuex'

export default {
  async mounted () {
    await this.check()
  },
  methods: {
    ...mapActions('user', ['addAbility', 'removeAbility', 'updateLocalStorage']),
    async check () {
      const url = '/user/controle_infinity/subscription/check'

      await this.$http.get(url)
        .catch(async e => {
          if (e.response.data === 'You do not have an active subscription.') {
            await this.addAbility('controle.buy')
            await this.removeAbility('controle.all')
            await this.updateLocalStorage()

            this.$router.push({ name: 'Controle Infinity (Assinar)' })
          }
        })
    }
  }
}
